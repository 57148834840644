import React from "react"
import { makeStyles } from "@material-ui/core"
import { LandingPageQuery_prismicLandingPage_data_body_PrismicLandingPageBodyCover as LandingCover } from "../../../__generated__/LandingPageQuery"
import { FadeIn } from "../../animations"
import { RichTextField, ThemeColor } from "../../../root/common"
import PrettyTitle from "../../atoms/PrettyTitle"
import { whenDesktop } from "../../../root/ui/mui"
import CTA from "../../atoms/CTA"
import { toLinkData } from "../../../root/contents/prismic"

interface Props {
  data: LandingCover
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
  },
  introTitle: {
    marginTop: theme.spacing(4),

    [whenDesktop(theme)]: {
      marginTop: theme.spacing(12),
      marginLeft: "22%",
      marginRight: "22%",
    },
  },
  introDescription: {
    marginTop: theme.spacing(10),

    [whenDesktop(theme)]: {
      marginLeft: "10%",
      marginRight: "10%",
    },
  },
  title: {
    marginTop: theme.spacing(10),

    [whenDesktop(theme)]: {
      marginLeft: "10%",
      marginRight: "10%",
    },
  },
  description: {
    marginTop: theme.spacing(10),

    [whenDesktop(theme)]: {
      marginLeft: "14%",
      marginRight: "14%",
    },

    "& p": {
      marginBottom: theme.spacing(4),
    },
  },
  ctaContainer: {
    marginTop: theme.spacing(6),
  },
}))

const CoverText = ({ data }: Props) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <div></div>
      <FadeIn>
        <RichTextField
          value={data.primary?.intro_title}
          variant="overline"
          weight="bold"
          className={classes.introTitle}
        />
        <RichTextField
          value={data.primary?.intro_description}
          variant="subtitle2"
          className={classes.introDescription}
        />
        <PrettyTitle
          value={data.primary?.title}
          className={classes.title}
          variant="h2"
        />
        <RichTextField value={data.primary?.subtitle} variant="subtitle1" />
        <RichTextField
          value={data.primary?.description}
          className={classes.description}
        />
        {data.primary?.cta_label && (
          <div className={classes.ctaContainer}>
            <CTA
              anchorTarget={data.primary?.cta_page_anchor ?? undefined}
              linkTarget={
                data.primary.cta_page_link
                  ? toLinkData(data.primary.cta_page_link)
                  : undefined
              }
              color={data.primary?.text_color as ThemeColor}
            >
              <RichTextField value={data.primary?.cta_label} variant="body2" />
            </CTA>
          </div>
        )}
      </FadeIn>
    </div>
  )
}

export default CoverText
