import { makeStyles } from "@material-ui/styles"
import classNames from "classnames"
import React from "react"
import { RichTextField } from "../../../root/common"
import { RichTextProps } from "../../../root/common/fields/RichTextField"

interface Props extends RichTextProps {}

const useStyles = makeStyles(() => ({
  root: {
    fontFamily: "blacker_pro",

    "& em": {
      fontFamily: "blacker_pro_italic",
    },
  },
}))

const PrettyTitle = ({ textClassName, ...other }: Props) => {
  const classes = useStyles()
  return (
    <RichTextField
      {...other}
      textClassName={classNames(classes.root, textClassName)}
    />
  )
}

PrettyTitle.defaultProps = {
  variant: "h1",
}

export default PrettyTitle
