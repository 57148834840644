import { Grid, makeStyles } from "@material-ui/core"
import React from "react"
import { RichTextField, ImageField } from "../../../root/common"
import { whenDesktop, whenMobile } from "../../../root/ui/mui"
import { LandingPageQuery_prismicLandingPage_data_body_PrismicLandingPageBodyFeaturesList_items as FeatureItems } from "../../../__generated__/LandingPageQuery"
import { FadeIn } from "../../animations"

interface Props {
  items: FeatureItems[]
  itemsWidth: number
}

const useStyles = makeStyles((theme) => ({
  root: {},
  item: {
    textAlign: "center",
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),

    [whenDesktop(theme)]: {
      paddingLeft: "4%",
      paddingRight: "4%",
    },

    [whenMobile(theme)]: {
      margin: "0 auto",
    },
  },
  imageContainer: {
    marginTop: theme.spacing(2),
    width: "22%",
    margin: "0 auto",
    paddingBottom: theme.spacing(4),
    minHeight: theme.spacing(10),
  },
  title: {
    paddingLeft: "26%",
    paddingRight: "26%",

    [whenMobile(theme)]: {
      paddingLeft: "16%",
      paddingRight: "16%",
    },
  },
  itemContainer: {
    [whenMobile(theme)]: {
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
}))

const FeaturesVertical = ({ items, itemsWidth }: Props) => {
  const classes = useStyles()
  return (
    <Grid container className={classes.root}>
      {items.map((item, index) => (
        <Grid
          item
          sm={12}
          md={itemsWidth as any}
          key={index}
          className={classes.itemContainer}
        >
          <FadeIn className={classes.item}>
            <div className={classes.imageContainer}>
              <ImageField value={item.item_image} />
            </div>
            <RichTextField
              value={item.item_title}
              weight="bold"
              className={classes.title}
            />
            <RichTextField value={item.item_description} />
          </FadeIn>
        </Grid>
      ))}
    </Grid>
  )
}

FeaturesVertical.defaultProps = {
  itemsWidth: 3,
}

export default FeaturesVertical
