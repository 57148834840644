import React from "react"
import { makeStyles, Grid } from "@material-ui/core"
import { LandingPageQuery_prismicLandingPage_data_body_PrismicLandingPageBodyCover as LandingCover } from "../../../__generated__/LandingPageQuery"
import {
  ImageField,
  RichTextField,
  TextVariant,
  ThemeColor,
} from "../../../root/common"
import CTA from "../../atoms/CTA"
import { FadeIn } from "../../animations"
import { isEmtyRichText, toLinkData } from "../../../root/contents/prismic"
import PrettyTitle from "../../atoms/PrettyTitle"
import { whenDesktop, whenMobile } from "../../../root/ui/mui"
import Dash from "../../atoms/Dash"

interface Props {
  data: LandingCover
}

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: ({ withDescription }: any) =>
      withDescription ? "center" : undefined,
  },
  titleCol: {
    alignItems: "center",

    [whenMobile(theme)]: {
      textAlign: "center",
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(3),

      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  titleColInner: {
    [whenMobile(theme)]: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  subtitle: {
    textAlign: "right",
    letterSpacing: "4px",

    [whenMobile(theme)]: {
      textAlign: "center",
    },
  },
  descriptionCol: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    justifyContent: "flex-end",

    [whenMobile(theme)]: {
      // display: "none",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  descriptionColInner: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    paddingBottom: theme.spacing(4),

    [whenMobile(theme)]: {
      alignItems: "center",
      paddingBottom: theme.spacing(0),
      marginLeft: "auto",
      marginRight: "auto",
    },

    [whenDesktop(theme)]: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  logoContainer: {
    width: "100px",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  ctaContainer: {
    marginTop: theme.spacing(3),

    [whenMobile(theme)]: {
      marginTop: theme.spacing(6),
      paddingBottom: theme.spacing(5),
    },
  },
  descriptionContainer: {
    paddingTop: theme.spacing(8),

    [whenDesktop(theme)]: {
      paddingRight: "8%",
      paddingTop: theme.spacing(16),
    },
  },
  description: {
    paddingTop: theme.spacing(2),

    "& .MuiTypography-root": {
      marginBottom: theme.spacing(8),
    },

    "& .MuiTypography-root:last": {
      marginBottom: 0,
    },
  },
}))

const CoverHorizontal = ({ data }: Props) => {
  const classes = useStyles({
    withDescription: !isEmtyRichText(data.primary?.description),
  })
  return (
    <Grid container className={classes.root}>
      <Grid item sm={12} md={6} className={classes.titleCol}>
        <FadeIn className={classes.titleColInner}>
          <PrettyTitle
            value={data.primary?.title}
            variant={data.primary?.title_size as TextVariant}
          />
        </FadeIn>
      </Grid>
      <Grid item sm={12} md={6} className={classes.descriptionCol}>
        <FadeIn className={classes.descriptionColInner}>
          {data.primary?.subtitle && (
            <RichTextField
              value={data.primary?.subtitle}
              className={classes.subtitle}
            />
          )}
          {!isEmtyRichText(data.primary?.description) && (
            <div className={classes.descriptionContainer}>
              {data.primary?.intro_description_separator === "dash" && (
                <Dash width="40px" color="white" />
              )}
              <RichTextField
                value={data.primary?.description}
                className={classes.description}
              />
            </div>
          )}
          {data.primary?.logo?.url && (
            <div className={classes.logoContainer}>
              <ImageField value={data.primary?.logo} />
            </div>
          )}
          {data.primary?.cta_label && (
            <div className={classes.ctaContainer}>
              <CTA
                anchorTarget={data.primary?.cta_page_anchor ?? undefined}
                linkTarget={
                  data.primary.cta_page_link
                    ? toLinkData(data.primary.cta_page_link)
                    : undefined
                }
                color={data.primary?.text_color as ThemeColor}
              >
                <RichTextField
                  value={data.primary?.cta_label}
                  variant="body2"
                />
              </CTA>
            </div>
          )}
        </FadeIn>
      </Grid>
    </Grid>
  )
}

export default CoverHorizontal
