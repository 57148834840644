import React from "react"
import CustomContainer from "../../molecules/CustomContainer"
import { LandingPageQuery_prismicLandingPage_data_body_PrismicLandingPageBodyQuote as QuoteData } from "../../../__generated__/LandingPageQuery"
import { RichTextField, ThemeColor } from "../../../root/common"
import { getMuiColor, whenDesktop, whenMobile } from "../../../root/ui/mui"
import { makeStyles } from "@material-ui/core"
import Dash from "../../atoms/Dash"
import { FadeIn } from "../../animations"
import PrettyTitle from "../../atoms/PrettyTitle"
import CTA from "../../atoms/CTA"
import { toLinkData } from "../../../root/contents/prismic"

interface Props {
  data: QuoteData
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(6, 0, 6, 0),
    color: ({ textColor }: any) =>
      textColor ? getMuiColor(theme, textColor) : undefined,

    [whenDesktop(theme)]: {
      backgroundImage: ({ backgroundImage }: any) =>
        backgroundImage ? `url(${backgroundImage})` : undefined,
      backgroundSize: "45%",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "70% 65%",
    },
  },
  subtitle: {
    letterSpacing: "8px",
  },
  title: {},
  dash: {
    paddingTop: theme.spacing(8),
  },
  description: {
    paddingTop: theme.spacing(4),

    "& .MuiTypography-root ": {
      marginBottom: theme.spacing(6),
    },

    [whenDesktop(theme)]: {
      paddingRight: "66%",
    },
  },
  descriptionText: {
    "& .MuiTypography-root ": {
      marginBottom: theme.spacing(6),
    },
  },
  cta: {
    marginLeft: "auto",
    marginRight: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    [whenDesktop(theme)]: {
      paddingTop: "30vh",
    },
  },
  ctaText: {
    [whenMobile(theme)]: {
      letterSpacing: "2px",
    },
  },
  imageMobile: {
    widht: "100%",
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(4),
    [whenDesktop(theme)]: {
      display: "none",
    },
  },
  textBlock: {
    // paddingBottom: "30vh",
  },
}))

const Quote = ({ data }: Props) => {
  const classes = useStyles({
    textColor: data.primary?.text_color,
    backgroundImage: data.primary?.background_image?.url,
  })
  return (
    <CustomContainer
      className={classes.root}
      background={{
        color: data.primary?.background_color as ThemeColor,
        // image: data.primary?.background_image?.url ?? undefined,
        position: "center",
        fixed: true,
      }}
    >
      <FadeIn className={classes.textBlock}>
        <RichTextField
          value={data.primary?.subtitle}
          variant="overline"
          textClassName={classes.subtitle}
        />
        <PrettyTitle value={data.primary?.title} className={classes.title} />
        <div className={classes.dash}>
          <Dash color={data.primary?.text_color as ThemeColor} />
        </div>
        <RichTextField
          value={data.primary?.description}
          className={classes.description}
          textClassName={classes.descriptionText}
          variant="subtitle1"
        />
        {/* <div>
        <CTA
          anchorTarget={data.primary?.cta_anchor ?? undefined}
          linkTarget={toLinkData(data.primary?.cta_link)}
          color={data.primary?.text_color as ThemeColor}
        >
          <RichTextField
            value={data.primary?.cta_name}
            textClassName={classes.ctaText}
          />
        </CTA>
        </div> */}
      </FadeIn>
      <FadeIn className={classes.cta}>
        <CTA
          anchorTarget={data.primary?.cta_anchor ?? undefined}
          linkTarget={toLinkData(data.primary?.cta_link)}
          color={data.primary?.text_color as ThemeColor}
        >
          <RichTextField
            value={data.primary?.cta_name}
            textClassName={classes.ctaText}
          />
        </CTA>
      </FadeIn>
      <FadeIn>
        <img
          src={data.primary?.background_image?.url ?? undefined}
          className={classes.imageMobile}
        />
      </FadeIn>
    </CustomContainer>
  )
}

export default Quote
