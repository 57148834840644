import { Grid, makeStyles } from "@material-ui/core"
import classNames from "classnames"
import React from "react"
import { ImageField, RichTextField } from "../../../root/common"
import { whenDesktop, whenMobile } from "../../../root/ui/mui"
import { LandingPageQuery_prismicLandingPage_data_body_PrismicLandingPageBodyFeaturesList_items as FeatureItems } from "../../../__generated__/LandingPageQuery"
import { FadeIn } from "../../animations"

interface Props {
  items: FeatureItems[]
}

const useStyles = makeStyles((theme) => ({
  item: {
    display: "flex",
    marginTop: theme.spacing(2),

    [whenDesktop(theme)]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingLeft: "7%",
      paddingRight: "1%",
    },

    [whenMobile(theme)]: {
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
    },
  },
  itemEven: {
    [whenDesktop(theme)]: {
      marginTop: theme.spacing(16),
      flexDirection: "row-reverse",
      textAlign: "right",
      paddingLeft: "1%",
      paddingRight: "7%",
    },
  },
  itemTitle: {
    letterSpacing: "6px",
    marginBottom: theme.spacing(1),
  },
  itemDescription: {
    paddingRight: "5%",
  },
  image: {
    marginTop: theme.spacing(1),
    width: "40%",

    [whenDesktop(theme)]: {
      width: "30%",
      paddingRight: theme.spacing(3),
    },
  },
  imageEven: {
    [whenDesktop(theme)]: {
      paddingRight: 0,
      paddingLeft: theme.spacing(3),
    },
  },
  content: {
    flex: 1,

    [whenMobile(theme)]: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(4),
    },
  },
}))

const FeaturesHorizontalSpecular = ({ items }: Props) => {
  const classes = useStyles()
  return (
    <Grid container>
      {items.map((item, index) => (
        <Grid item sm={12} md={6} key={index}>
          <FadeIn
            delay={50 * index}
            className={classNames(classes.item, {
              [classes.itemEven]: index % 2 === 1,
            })}
          >
            {item.item_image?.url && (
              <div
                className={classNames(classes.image, {
                  [classes.imageEven]: index % 2 === 1,
                })}
              >
                <ImageField value={item.item_image} />
              </div>
            )}
            <div className={classes.content}>
              <RichTextField
                value={item.item_title}
                variant="subtitle2"
                color="secondary"
                className={classes.itemTitle}
              />
              <RichTextField
                value={item.item_description}
                variant="body2"
                className={classes.itemDescription}
              />
            </div>
          </FadeIn>
        </Grid>
      ))}
    </Grid>
  )
}

export default FeaturesHorizontalSpecular
