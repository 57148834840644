import React from "react"
import { range } from "../../utils/arrayUtils"
import TemporalLineHorizontal from "./TemporalLineHorizontal"
import TemporalLineVertical from "./TemporalLineVertical"
import { TimelineProps } from "./types"

const TemporalLine = ({
  orientation,
  edgeItems,
  edges,
  ...other
}: TimelineProps) => {
  const itemContents = edgeItems ?? range(1, edges ?? 0).map(() => undefined)
  if (orientation === "horizontal") {
    return <TemporalLineHorizontal edgeItems={itemContents} {...other} />
  }
  if (orientation === "vertical") {
    return <TemporalLineVertical edgeItems={itemContents} {...other} />
  }

  return <></>
}

TemporalLine.defaultProps = {
  color: "white",
  orientation: "horizontal",
}

export default TemporalLine
