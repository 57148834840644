import React from "react"
import {
  LandingPageQuery_prismicLandingPage_data as LandingPageData,
  LandingPageQuery_prismicLandingPage_data_body as LandingPageItem,
} from "../../../__generated__/LandingPageQuery"
import DefaultLayout from "../../../layouts/DefaultLayout"
import LandindingSlices from "./landingSlices"
import { HeaderStyle } from "../../../layouts/DefaultLayout/components/Header"
import {
  FooterLogo,
  FooterStyle,
  FooterText,
} from "../../../layouts/DefaultLayout/components/Footer"

interface Props {
  pageData: LandingPageData
}

const LandingPageContainer = ({ pageData }: Props) => {
  return (
    <DefaultLayout
      headerStyle={pageData.header_style as HeaderStyle}
      footerStyle={pageData.footer_style as FooterStyle}
      footerLogo={pageData.footer_logo as FooterLogo}
      footerText={pageData.footer_text_color as FooterText}
      footerExtraContent={{
        title: pageData.body1?.[0]?.primary?.title ?? undefined,
        content: pageData.body1?.[0]?.primary?.description ?? undefined,
      }}
    >
      <LandindingSlices items={pageData.body as LandingPageItem[]} />
    </DefaultLayout>
  )
}

export default LandingPageContainer
