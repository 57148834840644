import { Grid, makeStyles } from "@material-ui/core"
import React from "react"
import { RichTextField, ThemeColor } from "../../../root/common"
import { toLinkData } from "../../../root/contents/prismic"
import { whenMobile } from "../../../root/ui/mui"
import { LandingPageQuery_prismicLandingPage_data_body_PrismicLandingPageBodyCover as LandingCover } from "../../../__generated__/LandingPageQuery"
import { FadeIn } from "../../animations"
import CTA from "../../atoms/CTA"
import PrettyTitle from "../../atoms/PrettyTitle"

interface Props {
  data: LandingCover
}

const useStyles = makeStyles((theme) => ({
  title: {
    [whenMobile(theme)]: {
      paddingTop: theme.spacing(4),
    },
  },
  subtitle: {
    marginTop: theme.spacing(4),
    paddingRight: "16%",
  },
  cta: {
    marginTop: theme.spacing(4),
  },
}))

const CoverFramed = ({ data }: Props) => {
  const classes = useStyles()
  return (
    <Grid container>
      <Grid item sm={12} md={6}>
        <FadeIn>
          <PrettyTitle value={data.primary?.title} className={classes.title} />
          <RichTextField
            value={data.primary?.subtitle}
            variant="h5"
            className={classes.subtitle}
          />
          <CTA
            anchorTarget={data.primary?.cta_page_anchor ?? undefined}
            linkTarget={toLinkData(data.primary?.cta_page_link)}
            color={data.primary?.text_color as ThemeColor}
            className={classes.cta}
          >
            <RichTextField value={data.primary?.cta_label} />
          </CTA>
        </FadeIn>
      </Grid>
    </Grid>
  )
}

export default CoverFramed
