import React from "react"
import { makeStyles } from "@material-ui/core"
import { LandingPageQuery_prismicLandingPage_data_body_PrismicLandingPageBodyCover as LandingCover } from "../../../__generated__/LandingPageQuery"
import CustomContainer from "../../molecules/CustomContainer"
import { getMuiColor, whenMobile } from "../../../root/ui/mui"
import CoverHorizontal from "./CoverHorizontal"
import CoverVertical from "./CoverVertical"
import CoverFramed from "./CoverFramed"
import { BackgroundImagePosition, HorizontalMargins } from "../../../types"
import CoverText from "./CoverText"
import classNames from "classnames"

interface Props {
  data: LandingCover
}

const useStyles = makeStyles((theme) => ({
  root: {
    color: ({ textColor }: any) =>
      textColor ? getMuiColor(theme, textColor) : undefined,
    minHeight: ({ minHeight }: any) => minHeight ?? "100vh",
    paddingBottom: ({ paddingBottom }: any) => paddingBottom,

    [whenMobile(theme)]: {
      minHeight: ({ minHeightMobile }: any) => minHeightMobile,
      paddingBottom: ({ paddingBottomMobile }: any) => paddingBottomMobile,
    },
  },
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
}))

const Cover = ({ data }: Props) => {
  const classes = useStyles({
    textColor: data.primary?.text_color,
    minHeight: data.primary?.min_height,
    minHeightMobile: data.primary?.min_height_mobile,
    paddingBottom: data.primary?.padding_bottom,
    paddingBottomMobile: data.primary?.padding_bottom_mobile,
  })

  return (
    <CustomContainer
      background={{
        image: data.primary?.background_image?.url ?? undefined,
        colorValue: data.primary?.background_color ?? undefined,
        position: data.primary
          ?.background_image_position as BackgroundImagePosition,
        positionX: data.primary?.background_position_x ?? undefined,
        width: data.primary?.background_width ?? undefined,
        fixed: data.primary?.background_fixed ?? undefined,
      }}
      backgroundMobile={{
        image: data.primary?.background_image_mobile?.url ?? undefined,
        position: data.primary
          ?.background_mobile_image_position as BackgroundImagePosition,
        fixed: data.primary?.background_fixed ?? undefined,
      }}
      className={classNames(classes.root, classes.container)}
      horizontalMargins={data.primary?.horizontal_margins as HorizontalMargins}
      noGutters={data.primary?.layout === "text"}
    >
      {data.primary?.layout === "horizontal" && <CoverHorizontal data={data} />}
      {data.primary?.layout === "vertical" && <CoverVertical data={data} />}
      {data.primary?.layout === "framed" && <CoverFramed data={data} />}
      {data.primary?.layout === "text" && <CoverText data={data} />}
    </CustomContainer>
  )
}

export default Cover
