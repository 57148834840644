import { makeStyles, Typography } from "@material-ui/core"
import React from "react"
import { whenDesktop, whenMobile } from "../../../root/ui/mui"
import { FadeIn } from "../../animations"
import Dash from "../../atoms/Dash"
import FormController from "../../molecules/ContactsForm/FormController"
import CustomContainer from "../../molecules/CustomContainer"

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(16),
    paddingBottom: theme.spacing(16),

    [whenMobile(theme)]: {
      paddingTop: theme.spacing(10),
      paddingBottom: theme.spacing(12),
    },
    backgroundColor: theme.palette.primary.main,
    color: "white",
  },
  title: {
    letterSpacing: "6.74px",
  },
  titleContainer: {
    display: "flex",
    width: "100%",
    paddingBottom: theme.spacing(4),
  },
  dash: {
    marginTop: theme.spacing(1.75),
    marginLeft: theme.spacing(4),
    width: "30%",

    [whenMobile(theme)]: {
      display: "none",
    },
  },
  cta: {
    cursor: "pointer",
    borderBottom: "1px solid",
  },
  formText: {
    paddingTop: theme.spacing(4),
    lineHeight: "38px",

    [whenDesktop(theme)]: {
      paddingRight: "35%",
    },
  },
}))

const InfoForm = () => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <CustomContainer>
        <FadeIn>
          <div className={classes.titleContainer}>
            <Typography
              variant="subtitle1"
              className={classes.title}
              component="span"
            >
              RICHIEDI MAGGIORI INFORMAZIONI
            </Typography>
            <div className={classes.dash}>
              <Dash color="white" fullWidth height="1px" />
            </div>
          </div>

          <FormController>
            <Typography
              variant="subtitle2"
              className={classes.formText}
              component="span"
            >
              {"Scopri quello che possiamo fare per te, "}
              <span className={classes.cta}>{" richiedi informazioni "}</span>
              {" e sarai ricontattato al più presto"}
            </Typography>
          </FormController>
        </FadeIn>
      </CustomContainer>
    </div>
  )
}

export default InfoForm
