import React from "react"
import { makeStyles } from "@material-ui/core"
import CustomContainer from "../../molecules/CustomContainer"
import {
  LandingPageQuery_prismicLandingPage_data_body_PrismicLandingPageBodyFeaturesList as FeaturesListData,
  LandingPageQuery_prismicLandingPage_data_body_PrismicLandingPageBodyFeaturesList_items as FeatureItems,
} from "../../../__generated__/LandingPageQuery"
import Dash from "../../atoms/Dash"
import { ImageField, RichTextField } from "../../../root/common"
import {
  DesktopOnly,
  getMuiColor,
  MobileOnly,
  whenDesktop,
  whenMobile,
} from "../../../root/ui/mui"
import FeaturesHorizontal from "./FeaturesHorizontal"
import FeaturesHorizontalSpecular from "./FeaturesHorizontalSpecular"
import FeaturesVertical from "./FeaturesVertical"
import CTA from "../../atoms/CTA"
import { isEmtyRichText, toLinkData } from "../../../root/contents/prismic"
import { FadeIn } from "../../animations"
import ImagesCarousel from "../../molecules/ImagesCarousel"
import PrettyTitle from "../../atoms/PrettyTitle"

interface Props {
  data: FeaturesListData
}

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(2),
    color: ({ textColor }: any) =>
      textColor ? getMuiColor(theme, textColor) : undefined,
  },
  title: {
    textAlign: "center",
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    letterSpacing: "6px",

    [whenDesktop(theme)]: {
      paddingLeft: "6%",
      paddingRight: "6%",
    },
  },
  dashContainer: {
    paddingTop: theme.spacing(5),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  intro: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(0, 4, 2, 4),
  },
  introText: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),

    [whenDesktop(theme)]: {
      paddingLeft: "7%",
      paddingRight: "7%",
    },
  },
  imageContainer: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),

    [whenMobile(theme)]: {
      marginTop: theme.spacing(0),
    },
  },
  image: {
    maxWidth: "100%",
  },
  imageLabel: {
    color: "#ccc",
    letterSpacing: "2px",
  },
  listContainer: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    color: ({ itemsTextColor }: any) =>
      itemsTextColor ? getMuiColor(theme, itemsTextColor) : undefined,

    [whenMobile(theme)]: {
      paddingTop: theme.spacing(0),
    },
  },
  ctaContainer: {
    display: "flex",
    justifyContent: "center",
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(8),
  },
  carousel: {
    margin: theme.spacing(2, 0),

    [whenDesktop(theme)]: {
      marginRight: "12%",
      marginLeft: "12%",
    },
  },
}))

const FeaturesList = ({ data }: Props) => {
  const classes = useStyles({
    textColor: data.primary?.text_color,
    itemsTextColor: data.primary?.items_text_color,
  })
  return (
    <CustomContainer className={classes.root}>
      <div className={classes.dashContainer}>
        <Dash color="secondary" />
      </div>
      {!isEmtyRichText(data.primary?.intro) && (
        <FadeIn>
          <div className={classes.intro}>
            <RichTextField
              className={classes.introText}
              value={data.primary?.intro}
              variant="h4"
              textCenter
            />
          </div>
        </FadeIn>
      )}
      {!isEmtyRichText(data.primary?.title) && (
        <FadeIn>
          {data.primary?.items_layout === "horizontal" ? (
            <PrettyTitle
              value={data.primary?.title}
              className={classes.title}
              color="primary"
            />
          ) : (
            <RichTextField
              value={data.primary?.title}
              className={classes.title}
              color="primary"
              weight="bold"
            />
          )}
        </FadeIn>
      )}

      {!isEmtyRichText(data.primary?.subtitle) && (
        <FadeIn>
          <PrettyTitle
            value={data.primary?.subtitle}
            color="primary"
            textCenter
          />
        </FadeIn>
      )}

      {data.primary?.image?.url && (
        <FadeIn>
          <div className={classes.imageContainer}>
            <DesktopOnly>
              <ImageField
                value={data.primary?.image}
                className={classes.image}
              />
              <RichTextField
                value={data.primary.image_label}
                className={classes.imageLabel}
                variant="body2"
              />
            </DesktopOnly>
            <MobileOnly>
              <ImageField
                value={data.primary?.image_mobile}
                className={classes.image}
              />
              <RichTextField
                value={data.primary.image_mobile_label}
                className={classes.imageLabel}
                variant="body2"
              />
            </MobileOnly>
          </div>
        </FadeIn>
      )}

      {data.primary?.carousel?.document && (
        <div className={classes.carousel}>
          <ImagesCarousel item={data.primary.carousel.document as any} />
        </div>
      )}

      {data.items && data.items?.length > 0 && (
        <div className={classes.listContainer}>
          {data.primary?.items_layout === "horizontal" && (
            <FeaturesHorizontal
              items={data.items as FeatureItems[]}
              itemsWidth={data.primary.items_width ?? 6}
              titleColor="secondary"
            />
          )}
          {data.primary?.items_layout === "horizontal_specular" && (
            <FeaturesHorizontalSpecular items={data.items as FeatureItems[]} />
          )}
          {data.primary?.items_layout === "vertical" && (
            <FeaturesVertical
              items={data.items as FeatureItems[]}
              itemsWidth={data.primary.items_width ?? 3}
            />
          )}
        </div>
      )}
      <FadeIn>
        {(data.primary?.bottom_cta_anchor_target ||
          data.primary?.bottom_cta_link?.id) && (
          <div className={classes.ctaContainer}>
            <CTA
              color="black"
              anchorTarget={data.primary?.bottom_cta_anchor_target ?? undefined}
              linkTarget={toLinkData(data.primary?.bottom_cta_link)}
            >
              <RichTextField value={data.primary?.bottom_cta_label} />
            </CTA>
          </div>
        )}
      </FadeIn>
    </CustomContainer>
  )
}

export default FeaturesList
