import React from "react"
import { Grid, makeStyles } from "@material-ui/core"
import { LandingPageQuery_prismicLandingPage_data_body_PrismicLandingPageBodyFeaturesList_items as FeatureItems } from "../../../__generated__/LandingPageQuery"
import { ImageField, RichTextField, ThemeColor } from "../../../root/common"
import { whenDesktop, whenMobile } from "../../../root/ui/mui"
import { FadeIn } from "../../animations"

interface Props {
  items: FeatureItems[]
  itemsWidth: number
  titleColor?: ThemeColor
}

const useStyles = makeStyles((theme) => ({
  item: {
    display: "flex",

    [whenMobile(theme)]: {
      flexDirection: "column",
      alignItems: "center",
      padding: theme.spacing(4, 0),
      textAlign: "center",
    },

    [whenDesktop(theme)]: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      paddingLeft: "7%",
      paddingRight: "7%",
    },
  },
  image: {
    marginTop: theme.spacing(1),
    width: "40%",

    [whenDesktop(theme)]: {
      width: "8.5%",
      paddingRight: theme.spacing(3),
    },

    [whenMobile(theme)]: {
      width: "25%",
    },
  },
  content: {
    flex: 1,

    [whenMobile(theme)]: {
      marginTop: theme.spacing(2),
    },
  },
  title: {
    marginBottom: theme.spacing(1),
  },
}))

const FeaturesHorizontal = ({ items, itemsWidth, titleColor }: Props) => {
  const classes = useStyles()
  return (
    <Grid container>
      {items.map((item, index) => (
        <Grid item sm={12} md={itemsWidth as any} key={index}>
          <FadeIn className={classes.item}>
            {item.item_image?.url && (
              <div className={classes.image}>
                <ImageField value={item.item_image} />
              </div>
            )}
            <div className={classes.content}>
              <RichTextField
                value={item.item_title}
                variant="overline"
                className={classes.title}
                weight="bold"
                color={titleColor}
              />
              <RichTextField value={item.item_description} />
            </div>
          </FadeIn>
        </Grid>
      ))}
    </Grid>
  )
}

export default FeaturesHorizontal
