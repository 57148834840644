import { makeStyles } from "@material-ui/core"
import classNames from "classnames"
import React from "react"
import { getMuiColor } from "../../../root/ui/mui"
import { TimelineInnerProps } from "./types"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
  },
  item: {
    width: "100%",
    height: ({ edgeLength }: any) => edgeLength,
  },
  lineItem: {
    borderTop: ({ color }: any) => `solid 1px ${getMuiColor(theme, color)}`,
    borderLeft: ({ color }: any) => `solid 1px ${getMuiColor(theme, color)}`,
  },
  lineItemLast: {
    borderLeft: ({ color }: any) => `solid 1px ${getMuiColor(theme, color)}`,
  },
}))

const TemporalLineHorizontal = ({
  color,
  edgeItems,
  edgeLength,
}: TimelineInnerProps) => {
  const classes = useStyles({
    color,
    edgeLength,
  })
  return (
    <div className={classes.root}>
      {edgeItems.map((item, index) => (
        <div
          key={index}
          className={classNames(classes.item, {
            [classes.lineItem]: index < edgeItems.length,
            [classes.lineItemLast]: index === edgeItems.length,
          })}
        >
          {item}
        </div>
      ))}
    </div>
  )
}

export default TemporalLineHorizontal
