import React from "react"
import CustomContainer from "../../molecules/CustomContainer"
import { LandingPageQuery_prismicLandingPage_data_body_PrismicLandingPageBodyTimeline as TimelineData } from "../../../__generated__/LandingPageQuery"
import { makeStyles } from "@material-ui/core"
import { ImageField, RichTextField, ThemeColor } from "../../../root/common"
import {
  DesktopOnly,
  getMuiColor,
  MobileOnly,
  whenMobile,
} from "../../../root/ui/mui"
import TemporalLine from "../../molecules/TemporalLine"
import PrettyTitle from "../../atoms/PrettyTitle"

interface Props {
  data: TimelineData
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(10, 2, 14, 2),
    color: ({ textColor }: any) =>
      textColor ? getMuiColor(theme, textColor) : undefined,
  },
  title: {
    letterSpacing: "8px",
  },
  itemsContainer: {
    marginTop: theme.spacing(2),
    display: "flex",
    textAlign: "left",

    [whenMobile(theme)]: {
      flexDirection: "column",
      textAlign: "center",
    },
  },
  item: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    // marginTop: theme.spacing(2),
    // marginBottom: theme.spacing(2),
    minHeight: theme.spacing(18),
    // flex: 1,

    // [whenMobile(theme)]: {
    //   marginTop: theme.spacing(6),
    //   marginBottom: theme.spacing(6),
    // },
  },
  imageContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "8%",
    minHeight: theme.spacing(8),

    [whenMobile(theme)]: {
      width: "26%",
      minWidth: "26%",
      maxWidth: "26%",
    },
  },
  itemTitle: {
    marginLeft: "4%",

    [whenMobile(theme)]: {
      marginLeft: theme.spacing(2),
      "& .MuiTypography-root": {
        fontSize: theme.typography.body1.fontSize,
      },
    },
  },
  timelineContainer: {
    marginTop: theme.spacing(8),
    // marginLeft: "2%",
    // marginRight: "-1.7%",
  },
}))

const Timeline = ({ data }: Props) => {
  const classes = useStyles({
    textColor: data.primary?.text_color,
  })
  return (
    <CustomContainer
      className={classes.root}
      background={{
        color: data.primary?.background_color as ThemeColor,
      }}
    >
      {/* <RichTextField
        value={data.primary?.title}
        variant="overline"
        textCenter
        textClassName={classes.title}
      /> */}
      {/* <PrettyTitle
          value={data.primary?.title}
          textClassName={classes.title}
          variant="h2"
        /> */}
      <DesktopOnly>
        <PrettyTitle
          value={data.primary?.title}
          textClassName={classes.title}
          variant="h2"
        />
      </DesktopOnly>
      <MobileOnly>
        <PrettyTitle
          value={data.primary?.title}
          textClassName={classes.title}
          variant="h3"
        />
      </MobileOnly>
      <div className={classes.timelineContainer}>
        <TemporalLine
          edgeLength="8%"
          orientation="vertical"
          itemMargin="4%"
          edgeItems={data.items?.map((item, index) => (
            <div key={index} className={classes.item}>
              <div
                className={classes.imageContainer}
                //
              >
                <div style={{ maxWidth: item?.width ?? undefined }}>
                  <ImageField value={item?.item_logo} />
                </div>
              </div>
              <RichTextField
                value={item?.item_title}
                className={classes.itemTitle}
                variant="subtitle1"
              />
            </div>
          ))}
        />
      </div>
      {/* <div className={classes.itemsContainer}>
        {data.items?.map((item, index) => (
          <div key={index} className={classes.item}>
            <div
              className={classes.imageContainer}
              style={{ maxWidth: item?.width ?? undefined }}
            >
              <ImageField value={item?.item_logo} />
            </div>
            <RichTextField
              value={item?.item_title}
              className={classes.itemTitle}
            />
          </div>
        ))}
      </div> */}
    </CustomContainer>
  )
}

export default Timeline
