import React from "react"
import { ImageField } from "../../../root/common"
import { LandingPageQuery_prismicLandingPage_data_body_PrismicLandingPageBodyFeaturesList_primary_carousel_document_PrismicCarousel as CarouselData } from "../../../__generated__/LandingPageQuery"
import SwiperCarousel from "../../atoms/SwiperCarousel"

interface Props {
  item: CarouselData
}

const ImagesCarousel = ({ item }: Props) => {
  return (
    <SwiperCarousel
      slidesToShowXs={1}
      slidesToShowSm={1}
      slidesToShowMd={1}
      dots
      dotsPosition="center"
    >
      {item.data?.images?.map((x, index) => (
        <div key={index}>
          <ImageField value={x?.image} size="fluid" />
        </div>
      ))}
    </SwiperCarousel>
  )
}

export default ImagesCarousel
