import { makeStyles } from "@material-ui/core"
import classNames from "classnames"
import React from "react"
import { getMuiColor } from "../../../root/ui/mui"
import { TimelineInnerProps } from "./types"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  itemContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
  },
  item: {
    width: ({ edgeLength }: any) => edgeLength,
    display: "flex",
    flexDirection: "column",
  },
  lineItemFirst: {
    flex: 1,
  },
  lineItemTop: {
    flex: 1,
    borderLeft: ({ color }: any) => `solid 1px ${getMuiColor(theme, color)}`,
  },
  lineItem: {
    flex: 1,
    borderTop: ({ color }: any) => `solid 1px ${getMuiColor(theme, color)}`,
    borderLeft: ({ color }: any) => `solid 1px ${getMuiColor(theme, color)}`,
    position: "relative",
  },
  lineItemLast: {
    flex: 1,
    borderTop: ({ color }: any) => `solid 1px ${getMuiColor(theme, color)}`,
    position: "relative",
  },
  itemContent: {
    marginLeft: ({ itemMargin }: any) => itemMargin,
    flex: 1,
  },
  square: {
    position: "absolute",
    left: -3,
    top: -3,
    height: "5px",
    width: "5px",
    backgroundColor: ({ color }: any) => getMuiColor(theme, color),
  },
}))

const TemporalLineVertical = ({
  color,
  edgeItems,
  edgeLength,
  itemMargin,
}: TimelineInnerProps) => {
  const classes = useStyles({
    color,
    edgeLength,
    itemMargin,
  })
  const isLast = (index: number) => index === edgeItems.length - 1
  const isFirst = (index: number) => index === 0
  return (
    <div className={classes.root}>
      {edgeItems.map((item, index) => (
        <div className={classes.itemContainer} key={index}>
          <div className={classes.item}>
            <div
              className={classNames({
                [classes.lineItemFirst]: isFirst(index),
                [classes.lineItemTop]: !isFirst(index),
              })}
            ></div>
            <div
              className={classNames({
                [classes.lineItem]: !isLast(index),
                [classes.lineItemLast]: isLast(index),
              })}
            >
              <div className={classes.square} />
            </div>
          </div>
          {item && <div className={classes.itemContent}>{item}</div>}
        </div>
      ))}
    </div>
  )
}

export default TemporalLineVertical
