import { Button, makeStyles } from "@material-ui/core"
import classNames from "classnames"
import { navigate } from "gatsby"
import React from "react"
import { SitePagesContext, ThemeColor } from "../../../root/common"
import { LinkValue } from "../../../root/common/fields/LinkField"
import { getMuiColor, whenMobile } from "../../../root/ui/mui"
import { scrollToElement } from "../../utils/scrolling"

export interface CTAData {
  children: any
  anchorTarget?: string
  linkTarget?: LinkValue
  color?: ThemeColor
  className?: string
  variant?: "text" | "outlined" | "contained"
  underline?: boolean
}

const useStyles = makeStyles((theme) => ({
  root: {
    color: ({ color }: any) => (color ? getMuiColor(theme, color) : undefined),
    borderColor: ({ color }: any) =>
      color ? getMuiColor(theme, color) : undefined,
    padding: theme.spacing(1.5, 5),
    letterSpacing: "3px",

    [whenMobile(theme)]: {
      padding: theme.spacing(1, 2),
    },
  },
  text: {
    padding: 0,
  },
  underline: {
    borderBottom: ({ color }: any) => `1px solid ${getMuiColor(theme, color)}`,
  },
}))

const CTA = ({
  color,
  children,
  className,
  variant,
  anchorTarget,
  linkTarget,
  underline,
}: CTAData) => {
  const classes = useStyles({
    color,
  })

  const sitePages = React.useContext(SitePagesContext)

  if (!anchorTarget && !linkTarget?.pageId && !linkTarget?.url) {
    return <></>
  }

  const handleCta = () => {
    if (anchorTarget) {
      scrollToElement(anchorTarget)
      return
    }

    if (linkTarget?.pageId) {
      const path = sitePages.getPagePath(linkTarget.pageId)
      if (!path) {
        throw Error("cannot resolve link")
      }
      navigate(path)
      return
    }

    if (linkTarget?.url) {
      window.open(linkTarget.url, "_blank")
    }
  }

  return (
    <Button
      className={classNames(
        classes.root,
        {
          [classes.text]: variant === "text",
          [classes.underline]: underline,
        },
        className
      )}
      variant={variant}
      onClick={handleCta}
    >
      {children}
    </Button>
  )
}

CTA.defaultProps = {
  color: "white",
  variant: "outlined",
}

export default CTA
