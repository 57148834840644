import { Theme, useTheme } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import React, { useRef } from "react"
import classNames from "classnames"

import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Pagination } from "swiper"
import { SwiperOptions } from "swiper/types/swiper-options"
import { useIsClient } from "../../../root/common"

interface StylesProps {
  marginBottom: number
  dotsPosition: DotsPosition
  slidePaddingX: number | undefined
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginBottom: (props: StylesProps) => theme.spacing(props.marginBottom),
    height: "100%",
  },
  slideContainer: {
    paddingLeft: (props: StylesProps) => props.slidePaddingX,
    paddingRight: (props: StylesProps) => props.slidePaddingX,
    "&:focus": {
      outline: "none",
    },
    height: "100%",
  },
  bulletsContainer: {
    display: "flex",
    justifyContent: "center",
    paddingTop: theme.spacing(2),
    paddingLeft: (props: StylesProps) => props.slidePaddingX,
    paddingRight: (props: StylesProps) => props.slidePaddingX,
    textAlign: (props: StylesProps) => props.dotsPosition,
  },
  bullet: {
    margin: "0 5px",
    width: "35px",
    height: "3px",
    cursor: "pointer",
    borderRadius: "10px",
    backgroundColor: theme.palette.secondary.main,
  },
  bulletActive: {
    backgroundColor: theme.palette.primary.main,
  },
}))

type DotsPosition = "left" | "right" | "center"

interface Props {
  children: any
  slidesToShowXs?: number
  slidesToShowSm?: number
  slidesToShowMd?: number
  dots?: boolean
  dotsPosition?: DotsPosition
  dotsClassName?: string
  nextSlidePreview?: boolean
  centerPadding?: number
  slidePaddingX?: number
  slideNextOnCkick?: boolean
  className?: string
}

const SwiperCarousel = ({
  children,
  slidesToShowXs,
  slidesToShowSm,
  slidesToShowMd,
  dots,
  dotsClassName,
  dotsPosition,
  centerPadding,
  nextSlidePreview,
  slidePaddingX,
}: Props) => {
  const classes = useStyles({
    marginBottom: dots ? 3 : 0,
    dotsPosition,
    slidePaddingX,
  })
  const theme = useTheme()
  const swiperBulletRef = useRef(null)
  SwiperCore.use([Pagination])

  const settings: SwiperOptions = {
    autoHeight: true,
    loop: true,
    centeredSlides: nextSlidePreview ?? false,
    spaceBetween: centerPadding,
    slidesPerView: slidesToShowXs,
    pagination: {
      el: swiperBulletRef.current as any,
      clickable: true,
      bulletActiveClass: classes.bulletActive,
      bulletElement: "div",
      bulletClass: classNames(classes.bullet, dotsClassName),
    },
    breakpoints: {
      [theme.breakpoints.values.md]: {
        centeredSlides: nextSlidePreview ?? false,
        slidesPerView: slidesToShowMd,
      },
      [theme.breakpoints.values.sm]: {
        centeredSlides: nextSlidePreview ?? false,
        slidesPerView: slidesToShowSm,
      },
    },
  }

  const isClient = useIsClient()

  return (
    <div
      className={classNames(classes.root, classes.slideContainer, classNames)}
    >
      <Swiper key={isClient ? "client" : "server"} {...settings}>
        {children.map((node: any, index: number) => (
          <SwiperSlide key={index}>{node}</SwiperSlide>
        ))}
      </Swiper>
      <div className={classes.bulletsContainer} ref={swiperBulletRef}></div>
    </div>
  )
}

SwiperCarousel.defaultProps = {
  slidesToShowXs: 1,
  slidesToShowSm: 2,
  slidesToShowMd: 3,
  centerPadding: 60,
  dotsPosition: "left",
}

export default SwiperCarousel
