import React from "react"
import { graphql } from "gatsby"
import { LandingPageQuery } from "../__generated__/LandingPageQuery"
import LandingPageContainer from "../containers/editorial/LandingPage"
import { withPrismicPreview } from "../root/contents/prismic/preview"
import { PrismicPage } from "../root/contents/prismic"

interface Props {
  data: LandingPageQuery
}

const LandingPage = ({ data }: Props) => {
  return (
    <PrismicPage page={data.prismicLandingPage}>
      {data.prismicLandingPage?.data && (
        <LandingPageContainer pageData={data.prismicLandingPage.data} />
      )}
    </PrismicPage>
  )
}

export default withPrismicPreview(LandingPage)

export const pageQuery = graphql`
  query LandingPageQuery($id: ID!) {
    prismicLandingPage(prismicId: { eq: $id }) {
      lang
      data {
        seo_title
        seo_description
        header_style
        footer_style
        footer_logo
        footer_text_color
        body {
          ... on PrismicLandingPageBodyCover {
            ...PrismicLandingPageBodyCoverData
          }
          ... on PrismicLandingPageBodyFeaturesList {
            ...PrismicLandingPageBodyFeaturesListData
          }
          ... on PrismicLandingPageBodyFeaturesGrid {
            ...PrismicLandingPageBodyFeaturesGridData
          }
          ... on PrismicLandingPageBodyFeatureIllustrations {
            ...PrismicLandingPageBodyFeatureIllustrationsData
          }
          ... on PrismicLandingPageBodyQuote {
            ...PrismicLandingPageBodyQuoteData
          }
          ... on PrismicLandingPageBodyTimeline {
            ...PrismicLandingPageBodyTimelineData
          }
          ... on PrismicLandingPageBodyWideText {
            ...PrismicLandingPageBodyWideTextData
          }
          ... on PrismicLandingPageBodyForm {
            ...PrismicLandingPageBodyFormData
          }
        }
        body1 {
          ... on PrismicLandingPageBody1FooterExtraText {
            ...PrismicLandingPageBody1FooterExtraTextData
          }
        }
      }
    }
  }
`
