import { makeStyles } from "@material-ui/core"
import classNames from "classnames"
import React from "react"
import {
  ImageField,
  RichTextField,
  TextVariant,
  ThemeColor,
} from "../../../root/common"
import { isEmtyRichText, toLinkData } from "../../../root/contents/prismic"
import { whenDesktop, whenMobile } from "../../../root/ui/mui"
import { LandingPageQuery_prismicLandingPage_data_body_PrismicLandingPageBodyCover as LandingCover } from "../../../__generated__/LandingPageQuery"
import { FadeIn } from "../../animations"
import CTA from "../../atoms/CTA"
import Dash from "../../atoms/Dash"
import PrettyTitle from "../../atoms/PrettyTitle"

interface Props {
  data: LandingCover
}

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: ({ verticalAlign }: any) =>
      verticalAlign === "top" ? "flex-start" : "center",

    [whenDesktop(theme)]: {
      paddingLeft: "14%",
      paddingRight: "14%",
    },
  },
  horizontalMarginsSm: {
    paddingLeft: "10%",
    paddingRight: "10",
  },
  horizontalMarginsXs: {
    paddingLeft: "4%",
    paddingRight: "4%",
  },
  horizontalMarginsNone: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  title: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(8),

    [whenMobile(theme)]: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(6),
    },
  },
  subtitle: {
    letterSpacing: "4px",

    [whenMobile(theme)]: {
      marginTop: theme.spacing(8),
      marginBottom: 0,
    },
  },
  descriptionContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(4),
  },
  description: {
    marginTop: theme.spacing(6),

    "& .MuiTypography-root ": {
      marginBottom: theme.spacing(6),
    },
  },
  cta: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    maxWidth: "22%",

    [whenMobile(theme)]: {
      maxWidth: "60%",
    },
  },
  introTitle: {
    marginTop: theme.spacing(4),
  },
  introDescription: {
    marginTop: theme.spacing(4),
  },
}))

const CoverVertical = ({ data }: Props) => {
  const classes = useStyles({
    verticalAlign: data.primary?.verical_alignment,
    horizontalMargins: data.primary?.horizontal_margins,
  })
  return (
    <div
      className={classNames(classes.root, {
        [classes.horizontalMarginsSm]:
          data.primary?.horizontal_margins === "sm",
        [classes.horizontalMarginsXs]:
          data.primary?.horizontal_margins === "xs",
        [classes.horizontalMarginsNone]:
          data.primary?.horizontal_margins === "none",
      })}
    >
      <FadeIn>
        <RichTextField
          value={data.primary?.intro_title}
          variant="overline"
          className={classes.introTitle}
        />
        <RichTextField
          value={data.primary?.intro_description}
          variant="subtitle2"
          className={classes.introDescription}
        />
      </FadeIn>
      <FadeIn>
        <PrettyTitle
          value={data.primary?.title}
          className={classes.title}
          variant={data.primary?.title_size as TextVariant}
        />
        <RichTextField
          value={data.primary?.subtitle}
          // variant="subtitle2"
          className={classes.subtitle}
        />
      </FadeIn>
      <FadeIn>
        {!isEmtyRichText(data.primary?.description) && (
          <div className={classes.descriptionContainer}>
            {data.primary?.intro_description_separator === "dash" && (
              <Dash width="40px" color="secondary" />
            )}
            <RichTextField
              value={data.primary?.description}
              className={classes.description}
            />
          </div>
        )}
        <CTA
          anchorTarget={data.primary?.cta_page_anchor ?? undefined}
          linkTarget={toLinkData(data.primary?.cta_page_link)}
          color={data.primary?.text_color as ThemeColor}
          className={classes.cta}
          variant="text"
        >
          {!isEmtyRichText(data.primary?.cta_label) && (
            <RichTextField value={data.primary?.cta_label} />
          )}
          {data.primary?.cta_image?.url && (
            <ImageField value={data.primary?.cta_image} />
          )}
        </CTA>
      </FadeIn>
    </div>
  )
}

export default CoverVertical
