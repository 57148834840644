import React from "react"
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt"
import CustomContainer from "../../molecules/CustomContainer"
import { LandingPageQuery_prismicLandingPage_data_body_PrismicLandingPageBodyFeatureIllustrations as FeatureIllustrationsData } from "../../../__generated__/LandingPageQuery"
import { Grid, makeStyles } from "@material-ui/core"
import { ImageField, LinkField, RichTextField } from "../../../root/common"
import Dash from "../../atoms/Dash"
import { getMuiColor, whenDesktop, whenMobile } from "../../../root/ui/mui"
import classNames from "classnames"
import PrettyTitle from "../../atoms/PrettyTitle"
import { FadeIn } from "../../animations"
import { isEmtyRichText, toLinkData } from "../../../root/contents/prismic"
import CTA from "../../atoms/CTA"

interface Props {
  data: FeatureIllustrationsData
}

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(6, 0, 0, 0),

    [whenMobile(theme)]: {
      marginBottom: 0,
    },
  },
  intro: {
    textAlign: "center",
  },
  title: {
    [whenDesktop(theme)]: {
      marginTop: theme.spacing(8),
    },
  },
  titleText: {
    letterSpacing: "8px",
  },
  subtitle: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(4),

    [whenDesktop(theme)]: {
      marginTop: theme.spacing(10),
    },
  },
  illustrationsContainer: {
    padding: theme.spacing(4, 0),
  },
  item: {
    [whenDesktop(theme)]: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(8),
      alignItems: "center",
    },

    [whenMobile(theme)]: {
      marginBottom: theme.spacing(10),
    },
  },
  itemLast: {
    [whenMobile(theme)]: {
      marginBottom: 0,
    },
  },
  itemReverse: {
    [whenDesktop(theme)]: {
      flexDirection: "row-reverse",
    },
  },
  descriptonCol: {
    marginTop: theme.spacing(2),

    [whenDesktop(theme)]: {
      paddingLeft: "5%",
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(8),
    },
  },
  descriptonColReverse: {
    [whenDesktop(theme)]: {
      paddingLeft: 0,
      paddingRight: "5%",
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(8),
    },
  },
  titleContainer: {
    display: "flex",
    alignItems: "flex-end",
  },
  titleContainerReverse: {
    [whenDesktop(theme)]: {
      flexDirection: "row-reverse",
    },
  },
  itemTitle: {
    letterSpacing: "8px",
  },
  dashContainer: {
    width: "18%",
    marginRight: theme.spacing(4),
    marginBottom: "5px",

    [whenMobile(theme)]: {
      width: "9%",
      marginRight: theme.spacing(2),
    },
  },
  dashContainerReverse: {
    [whenDesktop(theme)]: {
      marginRight: 0,
      marginLeft: theme.spacing(4),
    },
  },
  description: {
    marginTop: theme.spacing(2),

    [whenDesktop(theme)]: {
      marginTop: theme.spacing(4),
    },

    "& p": {
      marginBottom: theme.spacing(2),
    },
  },
  descriptionReverse: {
    [whenDesktop(theme)]: {
      textAlign: "right",
    },
  },
  ctaContainer: {
    width: "100%",
  },
  cta: {
    marginTop: theme.spacing(1),
  },
  ctaLabel: {
    borderBottom: `1px solid ${getMuiColor(theme, "primary")}`,
  },
}))

const useImageContainerStyles = makeStyles((theme) => ({
  root: {
    maxWidth: ({ maxWidth }: any) => maxWidth,

    [whenMobile(theme)]: {
      maxWidth: ({ maxWidthMobile }: any) => maxWidthMobile,
      marginBottom: theme.spacing(2),
    },
  },
}))

const ImageContainer = ({ maxWidth, maxWidthMobile, children }: any) => {
  const classes = useImageContainerStyles({
    maxWidth,
    maxWidthMobile,
  })
  return <div className={classes.root}>{children}</div>
}

const FeatureIllustrations = ({ data }: Props) => {
  const classes = useStyles()
  return (
    <CustomContainer className={classes.root}>
      <FadeIn>
        <div className={classes.intro}>
          <RichTextField
            value={data.primary?.title}
            variant="overline"
            color="secondary"
            weight="bold"
            className={classes.title}
            textClassName={classes.titleText}
          />
          <PrettyTitle
            value={data.primary?.subtitle}
            color="primary"
            className={classes.subtitle}
          />
        </div>
      </FadeIn>
      <div className={classes.illustrationsContainer}>
        {data.items?.map((item, index) => (
          <FadeIn key={index}>
            <Grid
              container
              className={classNames(classes.item, {
                [classes.itemLast]: index === (data.items?.length ?? 0) - 1,
                [classes.itemReverse]: index % 2 === 1,
              })}
            >
              <Grid item sm={12} md={5}>
                <ImageContainer
                  maxWidth={item?.image_max_width}
                  maxWidthMobile={item?.image_max_width_mobile}
                >
                  {item?.item_link?.url ? (
                    <LinkField value={toLinkData(item.item_link, "_blank")}>
                      <ImageField value={item?.main_image} size="fluid" />
                    </LinkField>
                  ) : (
                    <ImageField value={item?.main_image} size="fluid" />
                  )}
                </ImageContainer>
              </Grid>
              <Grid
                item
                sm={12}
                md={7}
                className={classNames(classes.descriptonCol, {
                  [classes.descriptonColReverse]: index % 2 === 1,
                })}
              >
                <div
                  className={classNames(classes.titleContainer, {
                    [classes.titleContainerReverse]: index % 2 === 1,
                  })}
                >
                  <div
                    className={classNames(classes.dashContainer, {
                      [classes.dashContainerReverse]: index % 2 === 1,
                    })}
                  >
                    <Dash
                      width="100%"
                      height="4px"
                      color="secondary"
                      radius="0"
                    />
                  </div>
                  <RichTextField
                    value={item?.item_title}
                    color="primary"
                    variant="overline"
                    weight="bold"
                    textClassName={classes.itemTitle}
                  />
                </div>
                <RichTextField
                  value={item?.item_description}
                  className={classNames(classes.description, {
                    [classes.descriptionReverse]: index % 2 === 1,
                  })}
                  color="primary"
                />
                {!isEmtyRichText(item?.cta_label) && (
                  <div className={classes.ctaContainer}>
                    {" "}
                    <CTA
                      linkTarget={toLinkData(item?.cta_link)}
                      color="primary"
                      className={classes.cta}
                      variant="text"
                    >
                      <RichTextField
                        value={item?.cta_label}
                        className={classes.ctaLabel}
                      />
                      <ArrowRightAltIcon style={{ marginLeft: 4 }} />
                    </CTA>
                  </div>
                )}
              </Grid>
            </Grid>
          </FadeIn>
        ))}
      </div>
    </CustomContainer>
  )
}

export default FeatureIllustrations
