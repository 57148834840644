import { animateScroll as scroll } from "react-scroll"

export interface Options {
  offset?: number
  duration?: number
}

export const scrollToElement = (elementId: string) => {
  const element = document.getElementById(elementId)
  if (!element) {
    throw Error(`Element ${elementId} not found`)
  }
  scroll.scrollTo(element.offsetTop)
}

export const scrollToRef = (ref: any) => scroll.scrollTo(ref.current.offsetTop)

export const scrollBottomToRef = (ref: any, options?: Options) =>
  scroll.scrollTo(
    ref.current.offsetTop +
      ref.current.offsetHeight -
      window.innerHeight +
      (options?.offset ?? 0),
    {
      duration: options?.duration,
    }
  )
