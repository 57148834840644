import React from "react"
import Cover from "../../../ui/organisms/Cover"
import FeatureIllustrations from "../../../ui/organisms/FeatureIllustrations"
import FeaturesList from "../../../ui/organisms/FeaturesList"
import FeaturesGrid from "../../../ui/organisms/FeauresGrid"
import InfoForm from "../../../ui/organisms/InfoForm"
import Quote from "../../../ui/organisms/Quote"
import Timeline from "../../../ui/organisms/Timeline"
import {
  LandingPageQuery_prismicLandingPage_data_body as LandingPageItem,
  LandingPageQuery_prismicLandingPage_data_body_PrismicLandingPageBodyCover as LandingCover,
  LandingPageQuery_prismicLandingPage_data_body_PrismicLandingPageBodyFeatureIllustrations as FeatureIllustrationsData,
  LandingPageQuery_prismicLandingPage_data_body_PrismicLandingPageBodyFeaturesList as FeaturesListData,
  LandingPageQuery_prismicLandingPage_data_body_PrismicLandingPageBodyFeaturesGrid as FeaturesGridData,
  LandingPageQuery_prismicLandingPage_data_body_PrismicLandingPageBodyQuote as QuoteData,
  LandingPageQuery_prismicLandingPage_data_body_PrismicLandingPageBodyTimeline as TimelineData,
} from "../../../__generated__/LandingPageQuery"

interface ItemProps {
  item: LandingPageItem
}

const LandindingSlice = ({ item }: ItemProps) => {
  return (
    <div id={(item as any).primary?.anchor_id ?? undefined}>
      {item.slice_type === "cover" && <Cover data={item as LandingCover} />}
      {item.slice_type === "feature_illustrations" && (
        <FeatureIllustrations data={item as FeatureIllustrationsData} />
      )}
      {item.slice_type === "features_list" && (
        <FeaturesList data={item as FeaturesListData} />
      )}
      {item.slice_type === "features_grid" && (
        <FeaturesGrid data={item as FeaturesGridData} />
      )}
      {item.slice_type === "quote" && <Quote data={item as QuoteData} />}
      {item.slice_type === "timeline" && (
        <Timeline data={item as TimelineData} />
      )}
      {item.slice_type === "form" && <InfoForm />}
    </div>
  )
}

interface Props {
  items: LandingPageItem[]
}

const LandindingSlices = ({ items }: Props) => {
  return (
    <>
      {items.map((x, index) => (
        <div key={index}>
          <LandindingSlice item={x} />
        </div>
      ))}
    </>
  )
}

export default LandindingSlices
