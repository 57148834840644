import React from "react"
import CustomContainer from "../../molecules/CustomContainer"
import { LandingPageQuery_prismicLandingPage_data_body_PrismicLandingPageBodyFeaturesGrid as FeaturesGridData } from "../../../__generated__/LandingPageQuery"
import { ImageField, RichTextField, ThemeColor } from "../../../root/common"
import { Grid, makeStyles } from "@material-ui/core"
import { getMuiColor, whenDesktop, whenMobile } from "../../../root/ui/mui"
import CTA from "../../atoms/CTA"
import { toLinkData } from "../../../root/contents/prismic"
import { FadeIn } from "../../animations"
import PrettyTitle from "../../atoms/PrettyTitle"

interface Props {
  data: FeaturesGridData
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(24, 0, 8, 0),
    color: ({ textColor }: any) =>
      textColor ? getMuiColor(theme, textColor) : undefined,

    [whenMobile(theme)]: {
      padding: theme.spacing(12, 0, 2, 0),
    },
  },
  titleContainer: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(6),

    [whenDesktop(theme)]: {
      maxWidth: "50%",
    },

    [whenMobile(theme)]: {
      paddingBottom: theme.spacing(2),
    },
  },
  itemsContainer: {
    paddingTop: theme.spacing(2),
  },
  item: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),

    [whenDesktop(theme)]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
      paddingRight: "10%",
    },
  },
  imageContainer: {
    width: "25%",

    [whenDesktop(theme)]: {
      width: "40%",
      minHeight: "140px",
    },
  },
  itemTitle: {
    marginTop: theme.spacing(2),
  },
  itemTitleText: {
    letterSpacing: "3px",
  },
  description: {
    marginTop: theme.spacing(4),

    [whenMobile(theme)]: {
      marginTop: theme.spacing(2),
    },
  },
  ctaContainer: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(8),
    textAlign: "center",

    [whenMobile(theme)]: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
  },
}))

const FeaturesGrid = ({ data }: Props) => {
  const classes = useStyles({
    textColor: data.primary?.text_color as ThemeColor,
  })
  return (
    <CustomContainer
      className={classes.root}
      background={{
        color: data.primary?.background_color as ThemeColor,
        image: data.primary?.background_image?.url ?? undefined,
        fixed: true,
      }}
    >
      <FadeIn>
        <div className={classes.titleContainer}>
          <PrettyTitle value={data.primary?.title} />
        </div>
      </FadeIn>
      <Grid container className={classes.itemsContainer}>
        {data.items?.map((item, index) => (
          <Grid item key={index} sm={12} md={4} className={classes.item}>
            <FadeIn>
              <div className={classes.imageContainer}>
                <ImageField value={item?.item_image} />
              </div>
              <RichTextField
                value={item?.item_title}
                variant="overline"
                className={classes.itemTitle}
                textClassName={classes.itemTitleText}
                color={data.primary?.items_title_color as ThemeColor}
              />
              <RichTextField
                value={item?.item_description}
                className={classes.description}
                variant="body2"
              />
            </FadeIn>
          </Grid>
        ))}
      </Grid>
      {data.primary?.bottom_cta_label && (
        <FadeIn>
          <div className={classes.ctaContainer}>
            <CTA
              color={data.primary?.text_color as ThemeColor}
              anchorTarget={data.primary.bottom_cta_target_anchor ?? undefined}
              linkTarget={
                data.primary.bottom_cta_link
                  ? toLinkData(data.primary.bottom_cta_link as any)
                  : undefined
              }
            >
              <RichTextField value={data.primary?.bottom_cta_label} />
            </CTA>
          </div>
        </FadeIn>
      )}
    </CustomContainer>
  )
}

export default FeaturesGrid
