import React, { CSSProperties } from "react"
import { useTheme } from "@material-ui/core/styles"
import classNames from "classnames"
import styled from "styled-components"
import { ThemeColor, ThemeColorVariant } from "../../../root/common"
import { getMuiColor } from "../../../root/ui/mui"

interface Props {
  color: ThemeColor
  colorVariant: ThemeColorVariant
  colorValue?: string
  width: string
  height: string
  radius: string
  noRounding?: boolean
  fullWidth?: boolean
  onClick?: () => void
  className?: string
  containerClassName?: string
  style?: CSSProperties
  children?: React.ReactNode
}

interface DashLineProps {
  customColor: string
  clickable: boolean
  width: string
  height: string
  borderRadius: string | undefined
}

const DashLine = styled.span<DashLineProps>`
  cursor: ${(props) => (props.clickable ? "pointer" : "")};
  background-color: ${(props) => props.customColor};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  border-radius: ${(props) => props.borderRadius};
  display: flex;
  justify-content: center;
  align-items: center;
`

interface DotContainerProps {
  width: string
  height: string
}

const DashContainer = styled.div<DotContainerProps>`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
`

const Dash = ({
  color,
  colorVariant,
  colorValue,
  width,
  height,
  radius,
  noRounding,
  fullWidth,
  onClick,
  className,
  containerClassName,
  style,
  children,
}: Props) => {
  const theme = useTheme()
  const getWidth = () => (fullWidth ? "100%" : width)
  return (
    <DashContainer
      style={style}
      width={getWidth()}
      height={height}
      className={classNames("dash-container", containerClassName)}
    >
      <DashLine
        className={classNames("dash", className)}
        onClick={onClick}
        customColor={colorValue ?? getMuiColor(theme, color, colorVariant)}
        clickable={onClick !== undefined}
        width="100%"
        height={height}
        borderRadius={noRounding ? undefined : radius}
      >
        {children}
      </DashLine>
    </DashContainer>
  )
}

Dash.defaultProps = {
  color: "primary",
  colorVariant: "main",
  width: "35px",
  height: "3px",
  radius: "10px",
}

export default Dash
